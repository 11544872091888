<template>
  <div :class="[`main-${id}`, 'home-class-main']" @mouseleave="actRemovePlayer">
    <div class="onhover-poster" :id="`onhover-${id}`"  :class="{ visible: selected, hidden: !selected }"

      @mouseleave="actRemovePlayer" >
      <div class="hover-card">
        <figure>
          <div style="height: 12rem;">
            <div v-if="onHoverSelectedItem && onHoverSelectedItem.isActiveVideo" style="position:relative;">
              <video :src="landscapeVideo" :id="`sliderVideoTrack-${id}`" loading="lazy" alt=""
                :poster="landscapseImage" class="image__item_video" muted autoplay loop @click="redirectToDetailPage(onHoverSelectedItem)" />
              <div class="mute-unmute-btn-slider" @click="ChangeMute" v-if="landscapeVideo">
                <VolumeIcon :status="ismuted ? 'mute' : 'unmute'" :dimensions="volumeIconDimensions" />
              </div>
              <div class="Episode-playbutton" v-if="onHoverSelectedItem.objecttype.toLowerCase() === 'content' && onHoverSelectedItem.category.toLowerCase() == 'tvshow'">
                <PlayButton :whereIamFrom="'episode'" :content="onHoverSelectedItem || null" :playerInstance="playerInstance"></PlayButton>
              </div>
              <div class="badge" style="position:absolute;padding:0.2rem;background:red;top: 5px; left: 5px;font-size: 0.8rem;border-radius:0.25rem;" v-if="onHoverSelectedItem.objecttag && onHoverSelectedItem.objecttag.length && onHoverSelectedItem.objecttag[0]">{{onHoverSelectedItem.objecttag[0]}}</div>
            </div>
            <img src="@/assets/placeholder-lands.svg" loading="lazy" class="image__item" v-else />
          </div>
          <figcaption class="related-meta-data">

            <div class="related-action-btns">
              <div class="watch-now-btn">
                <PlayButton :whereIamFrom="'hoverCard'" :content="onHoverSelectedItem || null" :playerInstance="playerInstance"></PlayButton>
              </div>
              <div class="add-to-watch-list">
                <WatchlistBtn :content="onHoverSelectedItem || null" v-if="selected" />
              </div>
              <div class="add-to-watch-list">
                <LikeBtn :content="onHoverSelectedItem || null" v-if="selected" />
              </div>
            </div>
            <div class="related-meta-data-details" v-if="onHoverSelectedItem">
              <h1 class="fig-title">{{ onHoverSelectedItem.title }}</h1>
              <div class="fig-details">
                <div class="main-info">
                  <div class="ua-rating" v-if="onHoverSelectedItem.advisory">
                    <p>{{ onHoverSelectedItem.advisory }}</p>
                  </div>
                  <div class="publish-year" v-if="onHoverSelectedItem.productionyear">
                    <p>{{ onHoverSelectedItem.productionyear }}</p>
                  </div>
                  <div class="duration" v-if="onHoverSelectedItem.duration">
                    <p>{{ this.calcTimeFormat(onHoverSelectedItem.duration) }}</p>
                  </div>
                  <div class="pgrating" v-if="onHoverSelectedItem.pgrating">
                    <p>{{ onHoverSelectedItem.pgrating }}</p>
                  </div>
                </div>
                <div class="main-genre">
                  <div class="sub-genre1" v-if="onHoverSelectedItem.category">
                    <p>{{ $t(onHoverSelectedItem.category) }}</p>
                  </div>
                  <div class="sub-genre2" v-if="onHoverSelectedItem.genre">
                    <p>{{ $t(onHoverSelectedItem.genre) }}</p>
                  </div>
                  <div class="sub-genre3" v-if="onHoverSelectedItem.contentlanguage && onHoverSelectedItem.contentlanguage.length > 0">
                    <p>{{ $t(onHoverSelectedItem.contentlanguage[0]) }}</p>
                  </div>
                </div>
              </div>
              <div class="fig-description" v-if="onHoverSelectedItem && onHoverSelectedItem.shortdescription">
                <!-- <span>{{onHoverSelectedItem.shortdescription}}</span> -->
                <span>{{ spliceText(onHoverSelectedItem.shortdescription, 90) }}</span>
              </div>
            </div>
          </figcaption>
        </figure>
      </div>
    </div>
    <div class="slider-category-title" v-if="screen && screen.title">{{ localDisplayLang === "ml" ? (screen.title.mal || screen.title.ar) : screen.title.default }}</div>
    <div class="slider-header">
      <div
        class="seeall"
        @click="seeAll(screen)"
        v-if=" (contentList && contentList.length > 13)">
        <div> {{ $t("More") }} </div>
        <div> <img src="@/assets/icons/rightarrow.svg" alt="more" class="seeall-arrow" /> </div>
      </div>
    </div>
    <div :class="['swiper', `sliderRow${id}`]" style="position: relative;">
      <div class="swiper-wrapper">
        <div :class="['swiper-slide', screen.displayType === 'LANDSCAPE' ? 'landscape-img-view' : 'portrait-img-view']"
          :id="`swiper-slide-${index}`" v-for="(item, index) of contentList" :key="index"
          @mouseover="handingTiming(true, item, $event)" @mouseleave.stop="actRemovePlayerX" @mouseout.stop="actRemovePlayerX">
          <img 
            :src="screen.displayType === 'LANDSCAPE' ? placeholder_lands : placeholder_port"
            :data-src="getPoster(item, screen.displayType === 'LANDSCAPE' ? 'LANDSCAPE' : 'PORTRAIT')"
            style="width: 100%;height:100%" @click="redirectToDetailPage(item)"
            @error="actLoadError" 
            data-lazy-load
            />
            <div class="badge" style="position:absolute;padding:0.2rem;background:red;top: 5px; left: 5px;font-size: 0.8rem;border-radius:0.25rem" v-if="item.objecttag && item.objecttag.length && item.objecttag[0]">{{item.objecttag && item.objecttag.length && item.objecttag[0]}}</div>
        </div>
      </div>
      <div class="swiper-button-next">
        <div class="next-btn-onhover"></div>
      </div>
      <div class="swiper-button-prev">
        <div class="prev-btn-onhover"></div>
      </div>
    </div>
  </div>
</template>

<script>
import { eventBus } from "@/eventBus";
import Utility from "@/mixins/Utility.js";
import Redirection from "@/mixins/redirection.js";
import { observerInterSection } from "@/modules/observerInterSection";

export default {
  props: ['title', 'id', 'screenName', 'screen', 'contentList', 'counterTextV', "playerInstance", "screenId"],
  data() {
    return {
      selected: false,
      selectedImage: false,
      localDisplayLang: 'en',
      onHoverSelectedItem: "",
      ismuted: true,
      Mute: require('@/assets/icons/mute.png'),
      Unmute: require('@/assets/icons/unmute.png'),
      placeholder_port: require('@/assets/placeholder-port.svg'),
      placeholder_lands: require('@/assets/placeholder-lands.svg'),
      cronjob: "",
      videoInstance: "",
      landscapseImage: "",
      landscapeVideo: "",
      posterPushed: false,
      isMobile: false,
      isDisableOnHover: true,
      timerOn: '',
      volumeIconDimensions: { width: '36', height: '36' },
      displayType: '',
      mouseLeaveTimeout: null
    }
  },
  components: {
    WatchlistBtn: () => import( /* webpackChunkName: "watchlisbutton" */ "@/components/SvgImages/WatchList.vue"),
    LikeBtn: () => import( /* webpackChunkName: "likeBtn" */ "@/components/SvgImages/LikeBtn.vue"),
    PlayButton: () => import(/* webpackChunkName: "playButton" */ "@/components/buttons/play.vue"),
    VolumeIcon: () => import(/* webpackChunkName: "volumeIcon" */ "@/components/SvgImages/Volume.vue"),
  },
  mixins: [Utility, Redirection],
  methods: {
    actLoadError (e) {
      e.target.src = placeholder_lands;
    },
    spliceText(text, maxLength) {
      if (text.length > maxLength) {
        return text.slice(0, maxLength) + '...'; // Splice text and add ellipsis
      } else {
        return text; // If text is within the limit, return it as is
      }
    },

    seeAll(screen) {
      let screenData = JSON.stringify(screen);
      localStorage.setItem("screen-list-collection", screenData);

      let currentLanguage = this.getCurrentLanguageUrlBase();


      let titleDefault = this.spacialCharEncoding(screen.title.default);
      this.$router.push({
        name: "collections",
        params: { section: titleDefault.toLowerCase(), slug: this.screenId, lang: currentLanguage },
      });

    },
    startMouseLeaveDetection() {
      // Add mousemove event listener to the document
      document.addEventListener('mousemove', this.handleMouseMove);
    },
    handleMouseMove(e) {
      const targetElement = document.querySelector(`#onhover-${this.id}`);
      if (targetElement) {
        const rect = targetElement.getBoundingClientRect();
        let isMouseInside = (
          e.clientX >= rect.left &&
          e.clientX <= rect.right &&
          e.clientY >= rect.top &&
          e.clientY <= rect.bottom
        );
        
        if (isMouseInside) {
           isMouseInside = true;
          if (this.mouseLeaveTimeout) {
            clearTimeout(this.mouseLeaveTimeout);
            this.mouseLeaveTimeout = null;
          }
        } else {
           isMouseInside = false;
          if (!this.mouseLeaveTimeout) {
            this.mouseLeaveTimeout = setTimeout(() => {
              if (!isMouseInside) {
                this.actRemovePlayer();
              }
            }, 300); // Delay before calling `actRemovePlayer`
          }
        }
      }
    },
    ChangeMute() {
      this.ismuted = !this.ismuted;
      const video = document.getElementById(`sliderVideoTrack-${this.id}`);
      video.muted = !video.muted;
    },
    getVideo(contentDetails) {
      return contentDetails.trailer && contentDetails.trailer[0] && contentDetails.trailer[0].filelist[0].filename ? contentDetails.trailer[0].filelist[0].filename : null;
    },
    getPoster(content, type) {
      // debugger
      if (Array.isArray(content.poster)) {
        if (content.poster && content.poster.length) {
          let index = content.poster.findIndex((element) => {
            return (element.quality === "SD" || element.quality === "HD") && element.postertype === type;
          });
          let img = 0;
          if (index > -1 && Array.isArray(content.poster[index]?.filelist)) {
            content.poster[index].filelist?.forEach(element => {
              // if (element.quality == 'LOW') {
              // return element.filename
              img = element.filename;
              // }
            });
            if (img) {
              return img
            } else {
              if (type == "LANDSCAPE") {
                return this.placeholder_lands;
              }
              return this.placeholder_port;
            }
          } else {
            if (type == "LANDSCAPE") {
              return this.placeholder_lands;
            }
            return this.placeholder_port;
          }
        }
      } else if (content.inwatchlist) {
        return content.poster.landscape;
      } else if(content?.contentprovider == "CATCHUPTV") {
        return content.thumbnail || this.placeholder_lands;
      } else if (content.thumbnail) {
        return this.placeholder_port;
      } else {
        if (type == "LANDSCAPE") {
          return this.placeholder_lands;
        }
        return this.placeholder_port;
      }
    },
    actDetailsPage() {
      this.$router.push({
        name: 'detailPageEarth', params: {
          title: this.onHoverSelectedItem.defaulttitle.replaceAll(" ", "-"),
          contentId: this.onHoverSelectedItem.objecttype == "SEASON" ? this.onHoverSelectedItem.seriesid : this.onHoverSelectedItem.objectid
        }
      })
    },
    actDetailsPageMobile(item) {
      this.$router.push({
        name: 'detailPageEarth', params: {
          title: item.title.replaceAll(" ", "-"),
          contentId: item.objecttype == "SEASON" ? item.seriesid : item.objectid
        }
      });
    },
    calcTimeFormat(val) {
      if (!val) return;
      let totalTime = val;
      let hours = Math.floor(totalTime / 3600);
      let minutes = Math.floor((totalTime - hours * 3600) / 60);

      let showTime =
        (hours ? hours.toString().padStart(2, "0") + this.$t("hr") : "") +
        " " +
        (minutes ? minutes.toString().padStart(2, "0") + (minutes === 1 ? this.$t("min") : this.$t("mins")) : "");

      if (showTime == " ") {
        return val + this.$t("secs");
      } else {
        return showTime;
      }
    },
    actMouseMove (e) {
      console.error("mouse move", e)
    },
    actRemovePlayer() {
      this.selected = false;

      setTimeout(() => {
        this.selected = false;
        this.onHoverSelectedItem.isActiveVideo = false;
      }, 100)

    },
    actRemovePlayerX() {
      clearTimeout(this.timerOn);
    },
    handingTiming(status, item, e) {
      clearTimeout(this.timerOn);
      this.timerOn = setTimeout(() => {
        this.actOnHoverPlayerActive(status, item, e)
      }, 1000);
    },
    actOnHoverPlayerActive(type, item, event) {
      if (this.isMobile || !this.isDisableOnHover) {
        return;
      }

      const targetItem = document.querySelector(`#onhover-${this.id}`);
      let sliderRow = document.querySelector(`.sliderRow${this.id}`);
      const targetElement = document.querySelector(`#onhover-${this.id}`);
      let rect = "";
      let mainElement = "";
      let xValue, yValue = 0;
      const maintainedValue = document.querySelector(`.sliderRow${this.id}`).getBoundingClientRect().x;

      this.selected = type;
      this.onHoverSelectedItem = item;
      this.landscapseImage = this.getPoster(item, 'LANDSCAPE')
      this.landscapeVideo = "";
      this.landscapeVideo = this.getVideo(item)
      this.onHoverSelectedItem.isActiveVideo = false;
      this.autoPosterVideoSwitch();
    
      if (targetElement) {
         rect = targetElement.getBoundingClientRect();
      }

      mainElement = event.target.getBoundingClientRect();

      xValue = (mainElement.x - maintainedValue) + ((mainElement.width / 2) - (rect.width / 2));
      yValue =  0;

      xValue = xValue < 0 ? 0 : xValue;
   
      if ( sliderRow.scrollWidth < mainElement.right ) {
        xValue = sliderRow.scrollWidth - rect.width;
      }
      
      targetItem.style.transform = `translate(${xValue}px, ${yValue}px)`;

      document.getElementsByClassName('top-carousel-class').forEach((item) => {
        item.muted = true
      })
      eventBus.$emit('event-isMuted')
    },
    autoPosterVideoSwitch() {
      this.onHoverSelectedItem = JSON.parse(JSON.stringify(this.onHoverSelectedItem));

      setTimeout(() => {
        this.onHoverSelectedItem.isActiveVideo = true;
      }, 1000)
    },
    cbFireScroll() {
      this.selected = false;
    },
  },

  mounted() {
    observerInterSection();
    this.startMouseLeaveDetection();
    // Add mousemove listener to the entire document
    this.isMobile = window.innerWidth <= 900 ? true : false,

      this.displayType = this.screen?.displayType == 'LANDSCAPE' ? 'LANDSCAPE' : 'PORTRAIT';

    this.localDisplayLang = localStorage.getItem("setDisplayLanguageCode");


    let breakpoints = {
      300: {
        slidesPerView: this.displayType == 'PORTRAIT' ? 3 : 2,  // Adjust the number of posters for mobile devices
        spaceBetween: 5  // Adjust the space between posters for mobile devices
      },
      420: {
        slidesPerView: this.displayType == 'PORTRAIT' ? 2.5 : 1.7,  // Adjust the number of posters for mobile devices
        spaceBetween: 5  // Adjust the space between posters for mobile devices
      },
      500: {
        slidesPerView: this.displayType == 'PORTRAIT' ? 3.5 : 2,  // Adjust the number of posters for mobile devices
        spaceBetween: 7  // Adjust the space between posters for mobile devices
      },
      630: {
        slidesPerView: this.displayType == 'PORTRAIT' ? 4 : 2.8,  // Adjust the number of posters for mobile devices
        spaceBetween: 10  // Adjust the space between posters for mobile devices
      },
      768: {
        slidesPerView: this.displayType == 'PORTRAIT' ? 4 : 3,  // Adjust the number of posters for mobile devices
        spaceBetween: 12  // Adjust the space between posters for mobile devices
      },

      992: {
        slidesPerView: this.displayType == 'PORTRAIT' ? 5.2 : 4.5,
        spaceBetween: 20
      },

      1024: {
        slidesPerView: this.displayType == 'PORTRAIT' ? 6.3 : 4.7,
        spaceBetween: 20
      },

      1500: {
        slidesPerView: this.displayType == 'PORTRAIT' ? 7.3 : 6.7,
        spaceBetween: 10
      },
      1800: {
        slidesPerView: this.displayType == 'PORTRAIT' ? 8.3 : 7.1,
        spaceBetween: 10
      }
    }


    let swiper = new window.Swiper(`.sliderRow${this.id}`, {
      slidesPerView: 2,  // Set the number of slides directly for testing
      cssMode: true,
      lazy: true,
      spaceBetween: 10,
      navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev",
      },
      on: {
        init: function () {
          this.isDisableOnHover = true;
        },
      },
      breakpoints,
      pagination: {
        el: ".swiper-pagination",
        clickable: true,
      },
    });

  }
}
</script>

<style scoped lang="scss">
@import "@/sass/_variables.scss";
@import "@/sass/_components.scss";

:root {
  --blue: #1e90ff;
  --white: $clr-saina-light-dark-txtf;
  --VERTICAL-CARD-HOVER-SCALE-X: 0.8;
  --VERTICAL-CARD-HOVER-SCALE-Y: 0.8;
}

.swiper {
  width: 100%;
  height: 100%;
}

.landscape-img-view {
  aspect-ratio: 16 / 9;
}

.portrait-img-view {
  aspect-ratio: 2 / 3;
}

.swiper-slide {
  text-align: center;
  font-size: 18px;
  background: #282828;
  /* background: white; */
  display: flex;
  justify-content: center;
  align-items: center;
  // height: 16rem;
  border-radius: 8px;
  border: 1px solid #282828;
  // pointer-events: none;
  z-index: 9;
  cursor: pointer;

}

.swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 8px;
}

.slider-category-title {
  font-size: 1.3rem;
  margin: 0.8rem 0.1rem;
  color: $clr-saina-light-dark-txt;
}

.swiper-button-next-overlay {
  height: 15rem;
  background-image: linear-gradient(to right, rgba(0, 0, 0, 0.85) 30%, rgba(0, 0, 0, 0.0) 90%);
  position: absolute;
  width: 3rem;
  top: 0px;
  display: block;
  z-index: 9999;
  // pointer-events: none;
  margin-top: auto !important;
}

::after {
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  left: 50%;
}

.swiper-button-next-overlay[aria-disabled="true"] .swiper-button-prev-overlay[aria-disabled="true"] {
  background-image: linear-gradient(to right, rgba(0, 0, 0, 0) 30%, rgba(0, 0, 0, 0.85) 90%);
}

.swiper-button-prev-overlay {
  height: 16rem;
  position: absolute;
  width: 3rem;
  top: 0px;
  right: 0;
  display: block;
  z-index: 9999;
  margin-top: auto !important;
}


.onhover-poster.visible {
  opacity: 1;
  visibility: visible;
}

.onhover-poster.hidden {
  opacity: 0;
  visibility: hidden;
}

.onhover-poster {
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.3s ease-in, visibility 0.3s ease-in, width 0.3s ease-in;
  position: absolute;
  z-index: 999;
  left: 0rem;
  cursor: pointer;
  .hover-card {
    z-index: 999;

    width: 20rem;
    height: 25rem;
    background: #101113;
    transform-origin: center center;
    position: relative;
    border-radius: 0.5rem;
    box-shadow: 0px 0px 5px 3px #00000073;

    figure {
      width: 100%;
      height: 50%;

      .image__item_video {
        width: 100%;
        height: 100%;
        border-radius: 0.5rem 0.5rem 0 0;
        height: 12rem;
        object-fit: cover;

      }

      .image__item {
        width: 100%;
        height: 100%;
        border-radius: 0.5rem 0.5rem 0;
      }

      .related-meta-data {
        padding: 0.75rem;
        display: flex;
        flex-direction: column;
        gap: 0.5rem;

        .related-action-btns {
          display: flex;
          gap: 1rem;
        }

        .related-meta-data-details {
          display: flex;
          flex-direction: column;
          gap: 0.5rem;

          .fig-title {
            font-weight: 500;
            font-size: 1rem;
          }

          .fig-details {
            display: flex;
            gap: 0.8rem;
            flex-direction: column;

            .main-info {
              display: flex;
              gap: 1rem;
              font-size: 0.85rem;
              align-items: center;

              .publish-year,
              .duration {
                color: #aaaaaa;
              }

              .pgrating {
                border: #3b3a3a 0.8px solid;
                padding: 0.1rem 0.4rem;
                border-radius: 0.2rem;
                color: #ffffff;
              }
            }

            .main-genre {
              display: flex;
              gap: 1rem;
              font-size: 0.85rem;
            }

            .main-genre .sub-genre2 p::before,
            .main-genre .sub-genre3 p::before {
              content: "• ";
              position: relative;
              right: 0.4rem;
            }
          }

          .fig-description {
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: pre-wrap;
            color: rgba(170, 170, 170, 1);
            font-weight: 400;
            font-size: 0.8rem;
            height: 3rem;
          }
        }
      }
    }
  }

}

.mute-unmute-btn-slider {
  position: absolute;
  right: 1rem;
  bottom: 2rem;
  z-index: 999999;
}

.Episode-playbutton {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}
.home-class-main {
 // margin-left: 1rem;
  position: relative;
}


.next-btn-onhover {
  background: #000;
  width: 4rem;
  height: 24rem;
  position: absolute;
  opacity: 0.3;
  border-radius: 10px;

}

.prev-btn-onhover {
  background: #000;
  width: 4rem;
  height: 24rem;
  position: absolute;
  opacity: 0.3;
  border-radius: 10px;
}

.slider-header {
  margin-top: 0.5rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 5px 0px 0.7rem;

  .seeall {
    cursor: pointer;
    font-size: 1.2rem;
    font-weight: 600;
    letter-spacing: 0.75px;
    padding: 0 5px;
    transition: 0.1s;
    align-self: center;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    right: 0rem;
    margin-bottom: 3rem;

    &:hover {
      transform: scale(1.05);
      color: $clr-light-gd3;
    }
  }

  .seeall-arrow {
    position: relative;
    top: 0.22rem;
    width: 1rem;
    height: 1.6rem;
  }

  .slider-head {
    display: flex;
    justify-content: flex-end;
    align-items: center;

    .slider-head-btns {
      margin-top: 3px;

      .slider-controls {
        button {
          border: none;
          background: transparent;


          cursor: pointer;

          img {
            height: 1.6rem;
            width: auto;
          }

          &:disabled {
            opacity: 0.3;
            display: flex;
          }

          &:focus {
            outline: none;
          }
        }

        &:focus {
          outline: none;
        }
      }
    }
  }
}

.swiper-button-prev, .swiper-rtl .swiper-button-next {
  transform: scale(0.7);
}
.swiper-button-next, .swiper-rtl .swiper-button-prev {
  transform: scale(0.7);

}

.slider-content {
  .content-slider {
    &:focus {
      outline: none;
    }
  }
}

@media screen and (max-width: 786px) {
  .next-btn-onhover, .prev-btn-onhover {
    width: 2.5rem;
    height: 5rem;
  }
}


</style>